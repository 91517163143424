import Badge from "../../components/Badge";
import Collapse from "../../components/Collapse";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { faq } from "../../constants";
import styles from "./faq.module.scss";

const Faq = () => {
  return (
    <div className={styles["faq"]} id="faq">
      <Header />
      <div className="container">
        <div className={styles["faq__titles"]}>
          <h2>FREQUENTLY ASKED QUESTIONS</h2>
          <h3>
            <Badge full>ABOUT MONKIES</Badge>
          </h3>
        </div>
        <div className={styles["faq__wrap"]}>
          {faq.map((item, idx) => (
            <Collapse key={item.id} id={item.id} title={item.name} idx={idx} >
              {item.text}
            </Collapse>
          ))}
        </div>
      </div>
      <div className={styles["footer"]}>
        <Footer />
      </div>
    </div>
  );
};

export default Faq;
