import {
  aboutImg1,
  aboutImg2,
  aboutImg3,
  aboutImg4,
  monkeysClub,
} from "../../assets/images";
import Badge from "../../components/Badge";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./about.module.scss";

const About = () => {
  return (
    <div className={styles["about"]} id="about">
      <Header />
      <div className="container">
        <div className={styles["intro"]}>
          <div>
            <h3 className={styles["intro__title"]}>
              <Badge>ABout</Badge>
            </h3>
            <h2>WHAT THE **** IS THE MONKIES CLUB?</h2>
            <p>
              Glad you asked! The Monkies Club is at the white-hot epicenter of
              P2E gaming, NFT collectibles with insane utility, passive income
              earning, and open-world metaverse exploration in VR. Like all
              those things? Hey, us too!
            </p>
            <p>
              At its heart, the Monkies Club is an exclusive collective of 4,445
              stylish, whip-smart Monkies stored on the Solana Blockchain.
              “Cool, so it’s just art?” No. Calm down. You didn’t let us finish.
              Your Monkie token is your all-access pass to an ever-expanding
              family of forward-thinking visionaries, thriving entrepreneurs,
              seasoned marketing veterans, and top-tier game developers.
              Together, we’re releasing a revolutionary P2E video game unlike
              anything the world’s ever seen. In short, your token is a path to
              fortune, glory, and a kingdom of tire swings as far as the eye can
              see. These Monkies are ready for anything. Are you? Of course you
              are, it was a rhetorical question. Never doubt yourself.
            </p>
            <p>
              Pop on over to our Discord and become a member of the Monkies Club
              today to ensure your Whitelist spot! They’re going fast.
            </p>
          </div>
          <img src={monkeysClub} alt="Monkeys club" />
        </div>
        <div className={styles["story"]}>
          <div className={styles["story__images"]}>
            <img src={aboutImg1} alt="Monkey" />
            <img src={aboutImg2} alt="Monkey" />
            <img src={aboutImg3} alt="Monkey" />
            <img src={aboutImg4} alt="Monkey" />
          </div>
          <div className={styles["story__info"]}>
            <h3>
              <Badge>The story</Badge>
            </h3>
            <h2>BANANAS ARE FOR CHUMPS, WE PLAY TO WIN</h2>
            <p>
              {" "}
              Here in our parallel universe, it’s been eons since anyone last
              saw a human. After their centralized system of greed, resentment,
              and corruption ate itself from the inside out, they just sort of…
              vanished. Maybe they bailed to mars like they were yapping about,
              who knows? Once the coast was clear, we Monkies descended the
              treetops to scope the scene. What we found was a bounty of fun
              things left behind for us to enjoy—tech gadgets, spiffy
              accessories, and head-turning apparel. We might not have made any
              of it, but damn, we looked good wearing it.
            </p>
            <p>
              Once we got our paws on their VR rigs and internet hookups, there
              was no taking us offline. Not to brag, but we made a lot of
              friends pretty quickly as we plumbed the depths of the metaverse.
              Our community became a central star around which free thought and
              innovation orbited. A true paradise. Sure, it would have been nice
              to take a breather, but there was no time to bask in the afterglow
              of being the metaverse’s most popular species. Part of the reason
              people loved us was because of our intelligence, adaptability, and
              forward-thinking culture, and we couldn’t just sit on that. We
              knew in our Monkie bones that the only way to keep our own
              civilization from falling to the same fate as the humans was to
              build a decentralized world that celebrates every individual’s
              potential to bring out the best in themselves and to be ready no
              matter the obstacles life throws at you.
            </p>
            <p>
              Naturally, any revolution is going to have its opposition. The
              moment we broke ground on our utopia, we heard what sounded like a
              loud horn or air raid alarm coming from a cave system near our
              clubhouse. After the terrible noise stopped, a battalion of
              slender, upright-walking creatures emerged from the cavern’s
              mouth. They made it clear negotiation wasn’t an option, so we beat
              the first group back into the inky black underground, but these
              monstrosities were reportedly trickling out of subterranean
              networks all over the world. While they varied in appearance, they
              all had long, awkward bodies, were almost completely hairless,
              looked a little crazy, and had the fashion sense of a turnip.
              Reports of birkenstocks and socks struck cold fear in the hearts
              of monkies all around the globe and while we couldn’t understand
              their language, our aggressors’ intention was clear—they were
              crawling up from the depths to inhabit the cities we’d rightly
              claimed and would stop at nothing to drive us out.
            </p>
            <p>
              Peace may be our ultimate objective, but now is the time to fight.
              We’ll arm ourselves, dig up as much protective gear as we can,
              push our community of monkies to embrace their inner badass, turn
              them into retro-futuristic Meta-Monkies, and step down from the
              safety of our townships to take the fight back to the
              poorly-dressed grotesqueries, whatever they are—or were.
            </p>
            <p>
              To improve our odds of safely defending our revolutionary society,
              we’ll split ourselves into five teams to divide and conquer. for
              60 days we’ll brave the open world to cull the most attackers and
              protect the homes we’d rightfully claimed as our own. At the end
              of two months, whichever team has racked up the highest score will
              be rewarded with fortune, glory, and the privilege of breeding the
              next generation of Monkies. You might be a human from another
              dimension, but we still need your help to get our peace-loving
              Monkies to embrace the fighting spirit. Sponsor a Monkie, unlock
              its full potential the more you play, dress it real slick to boost
              its confidence, and who knows? You might just end up the next hero
              of the Monkieverse.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
