import { Autoplay, Navigation } from "swiper";
import Badge from "../../components/Badge";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { game } from "../../constants";
import styles from "./game.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import "swiper/swiper.min.css";

const Game = () => {
  return (
    <div className={styles["game"]} id="game">
      <div className={styles['game__bg']} />
      <Header />
      <div className="container">
        <div className={styles["game__titles"]}>
          <h2>Game features</h2>
          <h3>
            <Badge full>Monkies Club</Badge>
          </h3>
        </div>
        <div className={styles["game__wrap"]}>
          <Swiper
            slidesPerView={1}
            className={styles.testomonials}
            autoHeight={true}
            spaceBetween={32}
            modules={[Navigation, Autoplay]}
            navigation={true}
            breakpoints={{
              567: {
                slidesPerView: 2,
              },
              968: {
                slidesPerView: 3,
              },
            }}
          >
            {game.map((item) => (
              <SwiperSlide key={item.id}>
                <div className={styles["game__block_wrap"]}>
                  <div className={styles["game__block"]}>
                    <h3>{item.name}</h3>
                    <div dangerouslySetInnerHTML={{ __html: item.text }} />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className={styles["footer"]}>
        <Footer />
      </div>
    </div>
  );
};

export default Game;
