import cn from "classnames";
import { FC } from "react";
import styles from "./index.module.scss";

interface IBadge {
  children: React.ReactNode;
  full?: boolean;
}

const Badge: FC<IBadge> = ({ children, full }) => {
  return (
    <div className={cn(styles["badge"], { [styles["badge_full"]]: full })}>
      <div className={styles["badge__text"]}>{children}</div>
    </div>
  );
};

export default Badge;
