import cn from "classnames";
import { FC, useState } from "react";
import styles from "./collapse.module.scss";

interface ICollapse {
  idx: number;
  title: string;
  id: number;
  children: any;
}

const Collapse: FC<ICollapse> = ({ idx = 1, title = "", id, children }) => {
  const [collapse, setCollapse] = useState<{
    id: number | null;
    status: boolean;
  }>({ id, status: false });

  const handleCollapse = (idx: number) => {
    setCollapse({
      ...(idx !== collapse.id
        ? { id: idx, status: true }
        : { status: !collapse.status, id: idx }),
    });
  };
  return (
    <div
      key={idx}
      onClick={() => handleCollapse(idx)}
      className={cn(styles["collapse"], {
        [styles["collapse_active"]]: collapse.id === idx && collapse.status,
      })}
    >
      <h3 className={cn(styles["collapse--title"])}>
        {title}
        <div className={styles["collapse--icon"]} />      </h3>
      <div
        className={cn(styles["collapse--desc"], {
          [styles["collapse--desc_active"]]:
            collapse.id === idx && collapse.status,
        })}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </div>
  );
};

export default Collapse;
