import { useState } from "react";
import { Arrow } from "../../assets/images";
import Badge from "../../components/Badge";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import { phases, phasesContent } from "../../constants";
import styles from "./roadmap.module.scss";

const Roadmap = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalName, setModalName] = useState<string>("");

  const openModal = (id: string) => {
    setIsOpen(true);
    setModalName(id);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div className={styles["roadmap"]} id="roadmap">
        <Header />
        <div className="container">
          <div className={styles["roadmap__titles"]}>
            <h2>Roadmap</h2>
            <h3>
              <Badge full>Monkies journey</Badge>
            </h3>
          </div>

          <div className={styles["phases"]}>
            {phases.map((phase) => (
              <button
                onClick={() => openModal(phase)}
                className={styles["phases__block"]}
                key={phase}
              >
                <div className={styles["phases__wrap"]}>
                  <div className={styles["phases__title"]}>Phase</div>
                  <div className={styles["phases__num"]}>{phase}</div>
                </div>
                <div className={styles["phases__arrow"]}>
                  <Arrow />
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className={styles["footer"]}>
          <Footer />
        </div>
      </div>
      <Modal isOpen={isOpen} closeModal={closeModal} modalName={modalName}>
        {modalName === phases[0] && (
          <div dangerouslySetInnerHTML={{ __html: phasesContent.first }} />
        )}
        {modalName === phases[1] && (
          <div dangerouslySetInnerHTML={{ __html: phasesContent.second }} />
        )}
        {modalName === phases[2] && (
          <div dangerouslySetInnerHTML={{ __html: phasesContent.third }} />
        )}
        {modalName === phases[3] && (
          <div dangerouslySetInnerHTML={{ __html: phasesContent.fourth }} />
        )}
      </Modal>
    </>
  );
};

export default Roadmap;
