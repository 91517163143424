import { introVideo } from "../../assets";
import Header from "../../components/Header";
import Mint from "../../components/Mint";
import styles from "./home.module.scss";

const HomePage = () => {
  return (
    <div className={styles["intro"]} id="intro">
      <video className={styles.intro__video} playsInline autoPlay muted loop>
        <source src={introVideo} type="video/mp4" />
      </video>
      <Header />
      <div className="container">
        <div className={styles["intro__info"]}>
          <div className={styles["intro__block"]}>
            <h1 className={styles["intro__title"]}>the Monkies Club</h1>
            <h3>
              Resurrecting the fundamental core of this space - providing
              utility and value
            </h3>
          </div>
          <div className={styles["intro__mint"]}>
            {/* <Mint /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
