import { FC } from "react";
import ReactModal from "react-modal";
import styles from "./index.module.scss";

interface IModal {
  isOpen: boolean;
  closeModal: React.Dispatch<boolean>;
  modalName: string;
  children: React.ReactNode,
}

const Modal: FC<IModal> = ({ isOpen, closeModal,  modalName, children }) => {
  
  return (
    <ReactModal
      className={styles["modal"]}
      overlayClassName={styles["overlay"]}
      isOpen={isOpen}
      onRequestClose={closeModal as any}
      ariaHideApp={false}
    >
      <div className={styles["modal__header"]}>
        <h2>
         PHASE {modalName}
        </h2>
        <button
          className={styles["modal__close-btn"]}
          onClick={closeModal as any}
        >
          &times;
        </button>
      </div>
      <div className={styles["modal__content"]}>{children}</div>
    </ReactModal>
  );
};

export default Modal;
