import { Twitter } from "../../assets/images";
import Badge from "../../components/Badge";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { team } from "../../constants";
import styles from "./team.module.scss";

const Team = () => {
  return (
    <div className={styles["team"]} id="team">
      <Header />
      <div className="container">
        <div className={styles["team__titles"]}>
          <h2>meet the team</h2>
          <h3>
            <Badge full>TEAM MEMBERS</Badge>{" "}
          </h3>
        </div>
        <div className={styles["team__wrap"]}>
          {team.map((item) => (
            <div key={item.id} className={styles["team__block"]}>
              <img src={item.img} alt={item.name} />
              <h3>{item.name}</h3>
              {item.position && <h4>{item.position}</h4>}
              {item.twitter && (
                <p>
                  <a href={item.twitter} rel="noreferrer" target="blank">
                    <Twitter />
                  </a>
                </p>
              )}
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles["footer"]}>
        <Footer />
      </div>
    </div>
  );
};

export default Team;
