import { team1, team2, team3, team4, team5, team6 } from "./assets/images";

export const PATHS = {
  home: "/",
  about: "/about",
  roadmap: "/roadmap",
  game: "/game",
  team: "/team",
  faq: "/faq",
};

export const menu = [
  { name: "home", path: "/" },
  { name: "about", path: "/about" },
  { name: "roadmap", path: "/roadmap" },
  { name: "game", path: "/game" },
  { name: "team", path: "/team" },
  { name: "faq", path: "/faq" },
];

export const faq = [
  {
    id: 1,
    name: "WHEN WILL THE OFFICIAL LAUNCH TAKE PLACE?",
    text: `TBA, keep an eye on our socials for Whitelist opportunities and dates! For more info, check out our ROADMAP`,
  },
  {
    id: 2,
    name: "WHAT IS THE METAVERSE?",
    text: `How was the coma? Glad you’re awake! The metaverse is an intersection of numerous technological elements such as VR, AR (augmented reality), and video where users “live” within a digital universe. Users who live in the metaverse can work, play, and stay connected with their friends by doing all the things they’d do in real life, but digitally—such as concerts, conventions, sporting events, games, virtual trips around the world, and more!
    `,
  },
  {
    id: 3,
    name: "WHAT IS AN NFT?",
    text: `A non-fungible token (NFT) is a non-interchangeable unit of data stored on a blockchain (a form of digital ledger—each cryptocurrency has its own blockchain). NFTs can be associated with reproducible digital files such as photos, videos, and audio. NFTs use a digital ledger to provide a public certificate of authenticity or proof of ownership, much like how a provenance proves a chain of legitimate ownership for a work of physical art from the artist to the current owner.
    `,
  },
  {
    id: 4,
    name: "HOW DO YOU PLAN TO DELIVER YOUR PROMISES?",
    text: `Methodically and effectively. Our Roadmap 1.0 is complete. In it, we lay out our gameplay for financing, partnering, and staggering releases of our project’s different offerings to keep interest stoked as we finalize the development of our game. Between our dedicated team’s commitment to the project and our partnership with top minds in the digital interactive space, we plan to leave no stone unturned and are prepared to meet any new needs as they arrive through our DAO.
    `,
  },
  {
    id: 5,
    name: "WHAT IS A DAO?",
    text: `A DAO (Decentralized Autonomous Organization) is governed entirely by its individual members who collectively make critical decisions about the future of the project. Items up for vote include technical upgrades, treasury allocations, and much more. Our priority in getting this project off the ground is stabilizing our DAO to achieve full decentralization.
    `,
  },
  {
    id: 6,
    name: "WHAT IS STAKING AND HOW DOES IT WORK?",
    text: `NFT staking refers to “locking up” non-fungible tokens on a platform or protocol in exchange for staking rewards and other benefits. Staking an NFT allows its holder to earn an income from their collection while maintaining ownership, rather than having to sell or rent it out. 
    `,
  },
  {
    id: 7,
    name: "DO MONKIES REALLY LIKE BANANAS?",
    text: `No. That is a myth.`,
  },
  {
    id: 8,
    name: "IF I’M RENTING AN NFT AND MY TEAM WINS IN COMPETITION MODE, DO I RECEIVE A META MONKIE NFT?",
    text: `Yes, all players on the winning team will receive their completely individually created Meta-Monkie NFT.    `,
  },
  {
    id: 9,
    name: "CAN I PLAY THE OPEN-WORLD GAME WHEN IT’S NOT IN COMPETITION MODE?",
    text: `After the competition mode, there will also be a free-roam mode where players can explore the open world until our next big thing comes along….
    `,
  },
  {
    id: 10,
    name: "IF I USE $VISION TO BUY ITEMS FOR MY NFT, BUT MY TEAM LOSES THE COMPETITION, DO I LOSE ALL OF MY ITEMS?",
    text: `No, but you won’t receive your Meta-Monkie that you created through in-game changes as an NFT.

    `,
  },
];

export const team = [
  {
    id: 1,
    img: team1,
    name: "LAWRENCE L.",
    position: "CO-FOUNDER",
    text: "A visionary who delivers what he promises. Also a successful entrepreneur in beautiful Switzerland, loving father, crypto whale and a true NFT enthusiast.",
  },
  {
    id: 2,
    img: team2,
    name: "Ismail B",
    position: "CO-FOUNDER",
    text: "Former chief game officer, smart contract auditor and the man who keeps an eye on everything behind the scenes.",
  },
  {
    id: 3,
    img: team3,
    name: "Olivia S.",
    position: "Head of Marketing",
    text: "Responsible for our marketing and manages a team of 9 professionals.",
  },
  {
    id: 4,
    img: team4,
    name: "Company to be announced",
    text: `The chosen ones who get the chance to create an amazing metaverse experience for our community and the most epic + innovative P2E game in the entire NFT space.
    Over 1700 full blooded professionals`,
  },
  {
    id: 5,
    img: team5,
    name: "GSD Club",
    text: `A large team of smart contract specialists that turn our complex visions into reality.`,
  },
  {
    id: 6,
    img: team6,
    twitter: 'https://twitter.com/Atitty_',
    name: "Atitty",
    position: 'Ambassador/Investor',
    text: `He literally needs no introduction`,
  },
];

export const phases = ["01", "02", "03", "04"];

export const game = [
  {
    id: 1,
    name: "DEVELOPMENT PARTNERSHIP",
    text: `
    <p>Our Monkies don’t do things half-assed. While other projects partner with their nephew who codes between soccer games, we’ve aligned with (name of the development company to be announced), a world-class development team with a proven track record of success. Their team’s 200+ development experts and 1,700+ employees have been working tirelessly to perfect our game’s mechanics, GUI, seamless integration with VR, and overall player experience. </p>
    <p>Working together, we’re on track to deliver the world’s first metaverse-based, open-world, VR-compatible P2E title with NFTs as playable characters.</p>
  `,
  },
  {
    id: 2,
    name: "A GAME UNLIKE ANY OTHER",
    text: `
    <p>Swap between VR or standard gameplay at any time (VR setup required for VR compatibility) and explore the dangerous world of the metaverse with your Monkie NFT. </p>
    <p>Shelve your fashion sense for practical sets of battle armor, cancel your meditation class and pick up crazy weapons to turn your enemies into Swiss cheese, lean into our staking mechanics to maximize your earning potential, and take advantage of our breeding function to expand your happy Monkie family before sending them into the fray. The world is littered with secrets, unlockables, and ultra-rare Legendary Monkies waiting to be unearthed.</p>
  `,
  },
  {
    id: 3,
    name: "TEAMWORK MAKES THE DREAM WORK",
    text: `
    <p>When game time comes, the total supply of 4,445 Monkies will be divided into 5 random teams of 889 players for 60 days of “Competition Mode.” During these two months, players earn $VISION (our ecosystem token) by completing missions simultaneously with the other four teams. At the end of the 60 days, the team with the most collective $VISION will win and each of the 889 players on the victorious team will earn his own Meta Monkie NFT that was personalized through in-game individualization.</p>
    <p>Each player receives 15 lives at the start of each Competition Mode period. Once the lives are used up, the player’s $VISION total is reset to zero and whatever amount they’d earned is deducted from their team’s total amount. In short… keep your eyes open and try not to die!</p>
    <p>Not only will the winning team equally split ALL of the $VISION the other teams have accrued during Competition Mode (exchangeable for $SOL), but anyone who owns a Meta Monkie is eligible to breed their NFT and make a Baby Monkie—the rarity of which is determined by both NFTs being bred. After a player successfully breeds a Baby Monkie, they’re granted access to our staking platform. The rarer the NFT being staked, the more tokens will be generated daily.</p>
    <p>Keep an eye out for our GDD (Game Design Document), which features all of the title’s finer details such as gameplay mechanics, progression functionality, and more!</p>
    `,
  },
  {
    id: 4,
    name: "MAY I BORROW A CUP… OF NFT?",
    text: `
    <p>If you’re familiar with NFTs, you know that right now they can only be sold or transferred. Well, guess what? Surprise! We’re changing the game, baby.</p>
    <p>Any token holder with more than 1x Monkies Club NFT is eligible to “rent” their NFTs to other players to earn passive income. Before Phase I of our Roadmap, multiple token holders will be invited to list their Monkie on our website. </p>
    <p>From there, the Lender simply lists their NFT and waits for Borrowers to send bids that dictate the initial down payment, daily rental rate, and duration of the rental period. At that point, the Lender is free to choose which bid is the most attractive. We’ll execute through a smart contract that deposits funds directly into the Lender’s wallet, transfers the NFT into the Borrower’s wallet, and automatically returns the NFT to its owner at the conclusion of the rental period.</p>
    <p>Note: once rented, the NFT cannot be transferred, sold, or rented again until it’s been returned to the Lender’s wallet. Sorry, no subletting!</p>`,
  },
  {
    id: 5,
    name: "RENTED NFTS IN COMPETITION MODE",
    text: `
    <p>Borrowing Players can use their rented NFT to participate in our open-world P2E game and contribute to the $VISION earnings of their team. If they are on the winning team, Rental Players earn a chance to enjoy the rewards owed to the winning team!</p>
    <p>Passive income has never been easier. For Lenders, 20% of the tokens earned by a Borrower during gameplay will automatically flow back into the Lender’s wallet passively. The remaining 80% will funnel into the team balance.</p>
    <p>More details about the rental mechanic will be unveiled in time, especially as we further incorporate it into gameplay as we enter Phase II of our Roadmap. Until then, because no one else is doing this with NFTs—keep it secret, keep it safe.</p>
    `,
  },
  {
    id: 6,
    name: "DRESS TO IMPRESS, TURN YOUR MONKIE INTO A META-MONKIE",
    text: `
    <p>Whenever your Monkie unlocks a weapon or new set of armor in-game, you’ll see the change reflected in your dynamic NFT portrait. Monkie NFT holders will find a wide array of customization options for their unique tokens, and the variety of choices available to turn in your Monkie into a Meta-Monkie means every token is completely individualized. With the volume of selections, you’ll never run into another Meta-Monkie that looks just like yours.</p>
    `,
  },
  {
    id: 7,
    name: "YOU’RE GONNA NEED MORE THAN THUMBS",
    text: `
    <p>It’s not all bananas and juicy bugs in the massive, hazardous world that is Monkies Club. Your Monkie won’t get far without the proper equipment, but fortunately, we’ve got you covered!</p>
    <p>Earn $VISION by completing missions and spend your $VISION to unlock weapons, armor, and everything else you need to safely traverse the digital world. Don’t go on a shopping spree, however, as the $VISION you use to purchase your gear will be deducted from your team’s overall amount. Choose strategically!</p>`,
  },
  {
    id: 8,
    name: "BOOST YOUR NFT’S VALUE",
    text: `
    <p>As you progress through the game, your achievements will have a significant impact on the value of your NFT, should you decide to sell during the Competition Mode phase. All tokens, items, and in-game levels will transfer along with your NFT to the new owner upon sale as your save game file is linked to your token. The more you’ve progressed, the more tokens and levels you’ll have achieved, making your NFT more attractive to a potential buyer.</p>`,
  },
  {
    id: 9,
    name: "COMPLETE SIDE QUESTS TO FIND THE FIVE SECRET LEGENDARY MONKIES",
    text: `
    <p>While five (5) Legendary Monkies will be available to mint, the remaining five (5) decided to hide out in the world where only the most committed Monkies Club members could find them. Complete exhilarating side quests to unlock a series of clues revealing their whereabouts and race all of Monkiedom to hunt the Legends down first.</p>`,
  },
];

export const phasesContent = {
  first: `
    <p>It’s a jungle out there in the metaverse! That’s why we’ve carefully charted our path to ensure our dominance as the world’s premier open-world, VR-compatible P2E title, and NFT community. Want to see our master plan? OK, but only if you pinky-promise not to tell anyone.</p>
    <h4>Are You On the List?</h4>
    <p>Opposable thumbs are great, but these 4,445 Monkies can’t bring harmony to the metaverse on their own. They need serious, committed partners sponsoring that is as ready to take over the metaverse as they are. As we build our community, we’ll be on the lookout for the most dedicated potential members to add to the Whitelist so they don’t get left behind. </p>
    <h4>Not Just an NFT</h4>
    <p>Every Monkie in our collection is so much more than a high-quality, 3D PFP—it can also be animated. Yes, every single one of them. Simply visit our website and toggle back and forth between a static and animated image for your Monkie NFT. You’ll never encounter a Monkie that looks exactly like yours, ever. But that’s just the beginning of the fun…</p>
    <h4>Achieve Legendary Status</h4>
    <p>Of the 10 Legendary 1/1 Monkies available for players to sponsor, 5 will be minted as part of our initial supply. Not only will a lucky few possess Monkie royalty, but the minters of our first 5 Legendary Monkies will also enjoy a rush of lifetime perks and heaps of jaw-dropping prizes. These include $2,500 in $SOL for each Legendary Mint, a trip to meet our founders and visit the progress of our philanthropic school-building charity work, higher staking rewards, VIP access to all future events, and a private suite in our metaverse Clubhouse.
    </p>
    <p>
    Didn’t mint a Legend? Don’t panic! The remaining 5 Legendary Monkies are hiding in our in-game side quests. The savviest players who hunt down all of our clues the fastest will earn the respect of these Legendary Monkies and will be allowed to claim one of these ultra-rare tokens, cementing their position at the top of the Monkie food chain. Better act quick, though—once all of the Legendaries have been picked up, they’re gone for good! </p>
    <h4>You Get a Banana! You Get a Banana! Everybody Gets a Banana!</h4>
    <p>Do you hate receiving gifts? If so, skip this section. As a token-holding member of the Monkie Club community, you’ll be auto-enrolled into weekly giveaways. These aren’t just freebies to flick your tail at—we’re rolling out the luxury goods because our Monkies are conditioned to the good life.</p>
    <h4>Pre-Battle Kickoff Party and Giveaways</h4>
    <p>These Monkies believe happiness is meant to be shared. That’s why after all of them have been minted and are safely in the hands of their chosen partners, they’ll offer their thanks by hosting several community giveaways and even an in-person members’ only rager.</p>
    <p>
        Peace talks with their mysterious underground invaders have failed, the time to take up arms and defend their homeland is now, and these Monkies want to wild out like it’s their last night on their newly-claimed Earth. They’ll leave it up to the DAO (decentralized autonomous organization) to decide where the members’ only event will be held. The world is our playground! When it comes time to pick, it’s completely up to the community. Just plan ahead, your Monkie will NOT be a suitable designated driver.</p>
    <h4>Game Design Document (GDD) Release</h4>
    <p>
    Our open-world, VR-compatible game is in the last phase of pre-production with an open beta soon to follow. While we promise a mind-blowing experience unlike anything else out there, the details must remain confidential until we release the Game Design Document (GDD) in the coming weeks. What we can promise is mayhem, Monkies, and more.
    </p>
    <p>
    We know you’re curious and we’ll provide answers as soon as we can! Our GDD will include the executive summary (complete game concept), gameplay (objectives, progression, in-game GUI, etc.), mechanics (rules, combat, physics, etc.), game elements (world-building, story, locations, etc.) assets (music, SFX) and more!
    </p>
    <h4>
    Let your voices be heard!
    </h4>
    <p>
    Decentralization is a core part of the Monkie Club philosophy, as is our readiness to meet our community’s needs, big or small. After all, #BornReady isn’t a platitude, it’s a promise. Our community deserves everything it wants and more, which is why each critical decision that affects the direction and future of the Monkies will be made through our DAO. Each Monkie holder has voting rights, so don’t sleep on your chance to make a difference!
    </p>`,
  second: `
    <p>We’ve sold our supply, we’ve hosted our meetups and delivered on many eye-popping giveaways, the Monkies have reluctantly completed their combat simulation training, and our game is nearing completion. Here’s how we keep the hype train rolling in Phase II.</p>
    <h4>A Place to Hang</h4>
    <p>Ever wanted a treehouse as a kid but Dad said “no” because you’d break your arm? Well, buckle up, baby, because in the metaverse, Dad’s not in charge anymore. Our Monkies have gotten sick of sleeping on the floor, so they built a state-of-the-art Clubhouse for meetups, kickbacks, parties, big events, DJ sets, impromptu raves, tasteful champagne brunches, and more! Want to host a metaverse event? Do it. The Clubhouse will be forever open to all Monkies, their token holders, and invited guests—24/7.</p>
    <p>
    We’ll look to our DAO to decide which metaverse will host the clubhouse… or maybe we’ll make a metaverse of our own? It’s entirely up to our community. Wherever it will live, the sky’s the limit! At the very least, it will have a banana vending machine.</p>
    <h4>Rotating Clubhouse Events</h4>
    <p>Every month, one of our token holders will be randomly selected to host an event in the Clubhouse. Throw a party, host a standup comedy night, or pitch one of your insanely cool ideas and have the DAO vote on it—it’s your event and you decide what happens. Play your cards right and you might just become a major part of Monkies Club history.
      </p>
    `,
  third: `
    <p>Our community is solid, we’re innovating and reinventing ourselves constantly, and our Monkies are ready and starting to feel the adrenaline rush as the dawn of battle nears. Here’s how we bring it all home in Phase III.
    </p>
    <h4>Beta Release</h4>
    <p>In Q1 (Jan-Apr) of 2023, our revolutionary Monkies Club open-world, VR-compatible P2E title will release in open beta. The game will be almost entirely complete, the beta will inform a few critical final tweaks before wide release, and everyone will be welcome to join our gameplay at this time. After the beta closes, the game will only be accessible by token holders.
    </p>
    <h4>Surprise Utility</h4>
    <p>Don’t tell anyone we told you, but after our beta release, we plan to unveil our token’s additional utility. While we can’t get into the nitty-gritty, this promises to be an unprecedented opportunity that will forever change the landscape of the NFT space and P2E games. Keep an eye on our social media channels for more info in the weeks following the beta.
    </p>
    <h4>Game Release</h4>
    <p>The moment you’ve been waiting for. This isn’t your average open-world, VR-compatible P2E title… mostly because nothing like that has existed until now.
    </p>
    <p>Not only will your Monkie function as your playable in-game character, but you can also control it in VR as it navigates a completely off-rails game designed to maximize action, inspire curiosity, and drive exploration of our massive world map. Find clues to rare tokens, arm yourself to the teeth, and shield yourself from the many dangers out there in our open-world with a slick set of armor. 
  </p>
  <p>Ours will be the first action-adventure, free-roam title with VR compatibility that combines P2E, NFT individualization, breeding, and staking. We’ve partnered with N-iX Game & VR Studio (Stellaris, Crusader Kings III), a world-renowned game development team with decades of experience and 1700+ employees that specialize in creating digital worlds as addictive as they are immersive.
  </p>
  <p>Don’t forget, the only way to play is by owning a Monkie NFT—so don’t miss your opportunity to pick one up before they’re all gone!
  </p>
     `,
     fourth: `
     <p>Our ideas and visions are too many to number, even if we wanted to! Phase IV will include the creation of a Roadmap 2.0, informed by our DAO after the completion of Roadmap 1.0. We want to ensure we’re honoring the needs of our community, which is why we plan to create the roadmap for our next immediate action items after we’ve completed the first round of plans.
  </p>
  <p>Stay tuned for more, keep an eye on our socials, and don’t forget to stay active for giveaways, partnered projects, and more! Keep safe, Monkies… it’s a jungle out there!
  </p>
     `
};
